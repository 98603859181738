export const Authorization = 'Authorization';
export const Token = 'token';
export const UserInfo = 'userInfo';
export const BotzillaRole = {
  ADMIN: 2,
  COMPANY: 1,
  USER: 0,
};
export const BotzillaRoleReq = {
  Home: BotzillaRole.USER,
  Knowledge: BotzillaRole.COMPANY,
  Chat: BotzillaRole.USER,
  CreateChat: BotzillaRole.COMPANY,
  GetCompanyLimits: BotzillaRole.COMPANY,
  Flow: BotzillaRole.COMPANY,
  File: BotzillaRole.COMPANY,
  SettingsSystem: BotzillaRole.ADMIN,
  SettingsModel: BotzillaRole.ADMIN,
  SettingsTeam: BotzillaRole.COMPANY,
  SettingsBotzillaAdmin: BotzillaRole.ADMIN,
  SettingsChatbotRights: BotzillaRole.COMPANY,
  SettingsCompanyCreateUser: BotzillaRole.COMPANY,
  SettingsCompanyTokens: BotzillaRole.COMPANY,
  SettingsAdminTokens: BotzillaRole.ADMIN,
  SettingsAdminCreateUser: BotzillaRole.ADMIN,
  SettingsApi: BotzillaRole.ADMIN,
};
