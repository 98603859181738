export default {
  translation: {
    common: {
      submit: 'Soumettre',
      default: 'Défaut',
      help: 'Aide',
      sent: 'Envoyé',
      pagination_go_to: 'Aller à',
      delete: 'Supprimer',
      deleteModalTitle: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
      ok: 'Oui',
      cancel: 'Annuler',
      total: 'Total',
      rename: 'Renommer',
      name: 'Nom',
      save: 'Enregistrer',
      namePlaceholder: 'Veuillez entrer un nom',
      next: 'Suivant',
      create: 'Créer',
      edit: 'Modifier',
      upload: 'Télécharger',
      english: 'Anglais',
      french: 'Français',
      language: 'Langue',
      languageMessage: 'Veuillez entrer votre langue !',
      languagePlaceholder: 'Sélectionnez votre langue',
      copy: 'Copier',
      copied: 'Copié',
      comingSoon: 'Bientôt disponible',
      download: 'Télécharger',
      close: 'Fermer',
      preview: 'Aperçu',
    },
    login: {
      passwordReset:
        'Un nouveau mot de passe a été envoyé à cette adresse mail',
      forgottenPassword: 'Réinitialiser mot de passe pour cette adresse mail',
      login: 'Connexion',
      signUp: 'Inscription',
      loginDescription: 'Nous sommes ravis de vous revoir !',
      registerDescription: 'Heureux de vous compter parmi nous !',
      emailLabel: 'Email',
      emailPlaceholder: 'Veuillez entrer un email',
      passwordLabel: 'Mot de passe',
      passwordPlaceholder: 'Veuillez entrer un mot de passe',
      rememberMe: 'Se souvenir de moi',
      signInTip: 'Vous n’avez pas de compte ?',
      signUpTip: 'Vous avez déjà un compte ?',
      nicknameLabel: 'Pseudo',
      nicknamePlaceholder: 'Veuillez entrer un pseudo',
      register: 'Créer un compte',
      continue: 'Continuer',
      title: 'Commencez à construire vos assistants intelligents.',
      description:
        'Inscrivez-vous gratuitement pour explorer la technologie RAG de pointe. Créez des bases de connaissances et des IA pour renforcer votre entreprise.',
      review: 'sur plus de 500 avis',
    },
    header: {
      knowledgeBase: 'Base de connaissances',
      chat: 'Chat',
      register: "S'inscrire",
      signin: 'Connexion',
      home: 'Accueil',
      setting: 'Paramètres utilisateur',
      logout: 'Déconnexion',
      fileManager: 'Gestion des fichiers',
      flow: 'Agent',
    },
    knowledgeList: {
      welcome: 'Bienvenue',
      description: '',
      createKnowledgeBase: 'Créer une base de connaissances',
      name: 'Nom',
      namePlaceholder: 'Veuillez entrer un nom !',
      doc: 'Documents',
      searchKnowledgePlaceholder: 'Rechercher',
    },
    knowledgeDetails: {
      parse_all: 'Traiter tous les documents',
      sortAsc: "Cliquer pour trier dans l'ordre ascendant",
      sortDesc: "Cliquer pour trier dans l'ordre descendant",
      cancelSort: 'Cliquer pour annuler tri',
      dataset: 'Jeu de données',
      testing: 'Test de récupération',
      files: 'fichiers',
      configuration: 'Configuration',
      name: 'Nom',
      namePlaceholder: 'Veuillez entrer un nom !',
      doc: 'Documents',
      datasetDescription:
        'Les questions et réponses ne peuvent être traitées qu’après une analyse réussie.',
      addFile: 'Ajouter un fichier',
      searchFiles: 'Cherchez vos fichiers',
      localFiles: 'Fichiers locaux',
      emptyFiles: 'Créer un fichier vide',
      webCrawl: 'Exploration Web',
      chunkNumber: 'Nombre de segments',
      uploadDate: 'Date de téléchargement',
      chunkMethod: 'Méthode de segmentation',
      enabled: 'Activé',
      disabled: 'Désactivé',
      action: 'Action',
      parsingStatus: 'État de l’analyse',
      processBeginAt: 'Début du traitement',
      processDuration: 'Durée du traitement',
      progressMsg: 'Message de progression',
      testingDescription:
        'Dernière étape ! Après le succès, laissez le reste à Botzilla.',
      similarityThreshold: 'Seuil de similarité',
      similarityThresholdTip:
        "Nous utilisons un score de similarité hybride pour évaluer la distance entre deux lignes de texte. Il s'agit d'une similarité de mots-clés pondérée et d'une similarité cosinus vectorielle. Si la similarité entre la requête et le segment est inférieure à ce seuil, le segment sera filtré.",
      vectorSimilarityWeight: 'Poids de la similarité des mots-clés',
      vectorSimilarityWeightTip:
        'Nous utilisons un score de similarité hybride pour évaluer la distance entre deux lignes de texte. Il est basé sur la similarité des mots-clés pondérés et la similarité cosinus vectorielle ou le score de réévaluation (0~1). La somme des deux poids est de 1,0.',
      testText: 'Texte de test',
      testTextPlaceholder: 'Veuillez entrer votre question !',
      testingLabel: 'Test',
      similarity: 'Similarité hybride',
      termSimilarity: 'Similarité des termes',
      vectorSimilarity: 'Similarité vectorielle',
      hits: 'Résultats',
      view: 'Voir',
      filesSelected: 'Fichiers sélectionnés',
      upload: 'Télécharger',
      run: 'Exécuter',
      runningStatus0: 'NON DÉMARRÉ',
      runningStatus1: 'Analyse',
      runningStatus2: 'ANNULÉ',
      runningStatus3: 'RÉUSSI',
      runningStatus4: 'ÉCHEC',
      pageRanges: 'Plages de pages',
      pageRangesTip:
        'Plages de pages : Définissez les plages de pages à analyser. Les pages qui ne sont pas incluses dans ces plages seront ignorées.',
      fromPlaceholder: 'de',
      fromMessage: 'Numéro de page de début manquant',
      toPlaceholder: 'à',
      toMessage: 'Numéro de page de fin manquant (exclus)',
      layoutRecognize: 'Reconnaissance de mise en page',
      layoutRecognizeTip:
        "Utilisez des modèles visuels pour l'analyse de la mise en page afin de mieux identifier la structure du document, de trouver où se trouvent les titres, les blocs de texte, les images et les tableaux. Sans cette fonctionnalité, seul le texte brut du PDF peut être obtenu.",
      taskPageSize: 'Taille de page de la tâche',
      taskPageSizeMessage: 'Veuillez entrer la taille de page de votre tâche !',
      taskPageSizeTip:
        'Si vous utilisez la reconnaissance de mise en page, le fichier PDF sera divisé en groupes de pages successives. L\'analyse de la mise en page sera effectuée en parallèle entre les groupes pour augmenter la vitesse de traitement. La "Taille de page de la tâche" détermine la taille des groupes. Plus la taille de la page est grande, plus faible est la probabilité de diviser le texte continu entre les pages en différents segments.',
      addPage: 'Ajouter une page',
      greaterThan: 'La valeur actuelle doit être supérieure à celle de "à" !',
      greaterThanPrevious:
        'La valeur actuelle doit être supérieure à la précédente "à" !',
      selectFiles: 'Sélectionner des fichiers',
      changeSpecificCategory: 'Changer de catégorie spécifique',
      uploadTitle:
        'Cliquez ou faites glisser le fichier dans cette zone pour le télécharger',
      uploadDescription:
        'Prise en charge du téléchargement unique ou en masse.',
      chunk: 'Segment',
      bulk: 'En masse',
      cancel: 'Annuler',
      rerankModel: 'Modèle de réévaluation',
      rerankPlaceholder: 'Veuillez sélectionner',
      rerankTip:
        'Si elle est vide. Il utilise les embeddings de la requête et des segments pour calculer la similarité cosinus vectorielle. Sinon, il utilise le score de réévaluation à la place de la similarité cosinus vectorielle.',
      topK: 'Top-K',
      topKTip: 'K segments seront introduits dans les modèles de réévaluation.',
    },
    knowledgeConfiguration: {
      titleDescription:
        "Mettez à jour les détails de votre base de connaissances, en particulier la méthode d'analyse ici.",
      name: 'Nom de la base de connaissances',
      photo: 'Photo de la base de connaissances',
      description: 'Description',
      language: 'Langue',
      languageMessage: 'Veuillez entrer votre langue !',
      languagePlaceholder: 'Veuillez entrer votre langue !',
      permissions: 'Permissions',
      embeddingModel: "Modèle d'incrustation",
      chunkTokenNumber: 'Nombre de jetons par segment',
      chunkTokenNumberMessage: 'Le nombre de jetons par segment est requis',
      embeddingModelTip:
        "Le modèle d'incrustation utilisé pour intégrer les segments. Il est inchangé une fois que la base de connaissances contient des segments. Vous devez supprimer tous les segments si vous souhaitez le changer.",
      permissionsTip:
        "Si la permission est 'Équipe', tous les membres de l'équipe peuvent manipuler la base de connaissances.",
      chunkTokenNumberTip:
        "Cela détermine approximativement le nombre de jetons d'un segment.",
      chunkMethod: 'Méthode de segmentation',
      chunkMethodTip: 'Les instructions sont à droite.',
      upload: 'Télécharger',
      english: 'Anglais',
      french: 'Français',
      chinese: 'Chinois',
      embeddingModelPlaceholder:
        "Veuillez sélectionner un modèle d'incrustation",
      chunkMethodPlaceholder:
        'Veuillez sélectionner une méthode de segmentation',
      save: 'Enregistrer',
      me: 'Seulement moi',
      team: 'Équipe',
      cancel: 'Annuler',
      methodTitle: 'Description de la méthode de segmentation',
      methodExamples: 'Exemples',
      methodExamplesDescription:
        "Les captures d'écran suivantes sont présentées pour faciliter la compréhension.",
      dialogueExamplesTitle: 'Exemples de dialogue',
      methodEmpty:
        'Ceci affichera une explication visuelle des catégories de la base de connaissances',
      book: `<p>Les formats de fichier supportés sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>Étant donné qu'un livre est long et que toutes les parties ne sont pas utiles, si c'est un PDF, veuillez configurer les <i>plages de pages</i> pour chaque livre afin d'éliminer les effets négatifs et d'économiser du temps de calcul pour l'analyse.</p>`,
      laws: `<p>Les formats de fichier supportés sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>Les documents juridiques ont un format d'écriture très rigoureux. Nous utilisons la caractéristique textuelle pour détecter le point de division.</p><p>La granularité du segment est cohérente avec 'ARTICLE', et tout le texte de niveau supérieur sera inclus dans le segment.</p>`,
      manual: `<p>Seul le <b>PDF</b> est pris en charge.</p><p>Nous supposons que le manuel a une structure de section hiérarchique. Nous utilisons les titres de section les plus bas comme pivots pour diviser les documents. Ainsi, les figures et les tableaux dans la même section ne seront pas divisés, et la taille du segment peut être grande.</p>`,
      naive: `<p>Les formats de fichier supportés sont <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p><p>Cette méthode applique les façons naïves de segmenter les fichiers :</p>
<li>Le texte successif sera divisé en morceaux en utilisant un modèle de détection visuelle.</li>
<li>Ensuite, ces morceaux successifs sont fusionnés en segments dont le nombre de jetons ne dépasse pas 'Nombre de jetons'.</li></p>`,
      paper: `<p>Seul le fichier <b>PDF</b> est pris en charge.</p><p>
Si notre modèle fonctionne bien, le document sera divisé par ses sections, comme <i>résumé, 1.1, 1.2</i>, etc. </p><p>
L'avantage de cela est que le LLM peut mieux résumer le contenu des sections pertinentes du document, 
résultant en des réponses plus complètes qui aident les lecteurs à mieux comprendre le document. 
L'inconvénient est que cela augmente le contexte de la conversation LLM et ajoute un coût computationnel, 
donc pendant la conversation, vous pouvez envisager de réduire le réglage ‘<b>topN</b>’.</p>`,
      presentation: `<p>Les formats de fichier pris en charge sont <b>PDF</b>, <b>PPTX</b>.</p><p>
Chaque page sera traitée comme un segment. Et la miniature de chaque page sera stockée.</p><p>
<i>Tous les fichiers PPT que vous avez téléchargés seront segmentés en utilisant cette méthode automatiquement, il n'est pas nécessaire de configurer chaque fichier PPT.</i></p>`,
      qa: `
<p>
Cette méthode de segmentation prend en charge les formats de fichier <b>EXCEL</b> et <b>CSV/TXT</b>.
</p>
<li>
Si le fichier est au format <b>Excel</b>, il doit comprendre deux colonnes
sans en-têtes : une pour les questions et l'autre pour les réponses, la colonne des questions précédant celle des réponses. Plusieurs feuilles sont acceptables tant que les colonnes sont correctement structurées.
</li>
<li>
Si le fichier est au format <b>CSV/TXT</b>, il doit être encodé en UTF-8 avec TAB
utilisé comme délimiteur pour séparer les questions et les réponses.
</li>
<p>
<i>
Les lignes de texte qui ne respectent pas les règles ci-dessus seront ignorées, et
chaque paire Q&R sera considérée comme un segment distinct.
</i>
</p>
`,
      resume: `<p>Les formats de fichier pris en charge sont <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
</p><p>
Le CV vient dans une variété de formats, tout comme la personnalité d'une personne, mais nous devons souvent les organiser en données structurées qui facilitent la recherche.
</p><p>
Au lieu de segmenter le CV, nous analysons le CV en données structurées. En tant que RH, vous pouvez déposer tous les CV que vous avez, 
puis vous pouvez lister tous les candidats qui correspondent aux qualifications juste en parlant avec <i>'Botzilla'</i>.
</p>
`,
      table: `<p>Les formats de fichier <b>EXCEL</b> et <b>CSV/TXT</b> sont pris en charge.</p><p>
Voici quelques conseils :
<ul>
<li>Pour les fichiers csv ou txt, le délimiteur entre les colonnes est <em><b>TAB</b></em>.</li>
<li>La première ligne doit être les en-têtes de colonnes.</li>
<li>Les en-têtes de colonnes doivent être des termes significatifs afin de faciliter la compréhension de notre LLM.
Il est bon d'énumérer quelques synonymes en utilisant la barre oblique <i>'/'</i> pour séparer, et encore mieux d'énumérer les valeurs en utilisant des parenthèses comme <i>'genre/sexe(masculin, féminin)'</i>.</p>
Voici quelques exemples pour les en-têtes :<ol>
<li>fournisseur/vendor<b>'TAB'</b>couleur(jaune, rouge, marron)<b>'TAB'</b>genre/sexe(masculin, féminin)<b>'TAB'</b>taille(M,L,XL,XXL)</li>
</ol>
</p>
</li>
<li>Chaque ligne du tableau sera traitée comme un segment.</li>
</ul>`,
      picture: `
<p>Les fichiers d'images sont pris en charge. La vidéo arrive bientôt.</p><p>
Si l'image contient du texte, l'OCR est appliqué pour extraire le texte comme description.
</p><p>
Si le texte extrait par OCR n'est pas suffisant, un LLM visuel est utilisé pour obtenir les descriptions.
</p>`,
      one: `
<p>Les formats de fichier pris en charge sont <b>DOCX, EXCEL, PDF, TXT</b>.
</p><p>
Pour un document, il sera traité comme un segment entier, sans division.
</p><p>
Si vous souhaitez résumer quelque chose qui nécessite tout le contexte d'un article et que la longueur de contexte du LLM sélectionné couvre la longueur du document, vous pouvez essayer cette méthode.
</p>`,
      useRaptor: 'Utiliser RAPTOR pour améliorer la récupération',
      useRaptorTip:
        'Traitement abstrait récursif pour la récupération organisée en arbre, veuillez consulter https://huggingface.co/papers/2401.18059',
      prompt: 'Invite',
      promptTip: 'Invite LLM utilisée pour la résumé.',
      promptMessage: "L'invite est requise",
      promptText: `Veuillez résumer les paragraphes suivants. Faites attention aux nombres, ne fabriquez pas de faits. Voici les paragraphes :
{cluster_content}
Ceci est le contenu que vous devez résumer.`,
      maxToken: 'Nombre maximum de jetons',
      maxTokenTip: 'Nombre maximum de jetons pour la résumé.',
      maxTokenMessage: 'Le nombre maximum de jetons est requis',
      threshold: 'Seuil',
      thresholdTip: 'Plus le seuil est élevé, moins il y aura de clusters.',
      thresholdMessage: 'Le seuil est requis',
      maxCluster: 'Nombre maximum de clusters',
      maxClusterTip: 'Nombre maximum de clusters.',
      maxClusterMessage: 'Le nombre maximum de clusters est requis',
      randomSeed: 'Graine aléatoire',
      randomSeedMessage: 'La graine aléatoire est requise',
      entityTypes: "Types d'entités",
    },
    chunk: {
      chunk: 'Segment',
      bulk: 'En masse',
      selectAll: 'Sélectionner tout',
      enabledSelected: 'Activer sélectionné',
      disabledSelected: 'Désactiver sélectionné',
      deleteSelected: 'Supprimer sélectionné',
      search: 'Recherche',
      all: 'Tous',
      enabled: 'Activé',
      disabled: 'Désactivé',
      keyword: 'Mot-clé',
      function: 'Fonction',
      chunkMessage: 'Veuillez entrer une valeur !',
      full: 'Texte intégral',
      ellipse: 'Ellipse',
      graph: 'Graphe de connaissances',
      mind: 'Carte mentale',
    },
    chat: {
      personnalizeIframe: 'Personnaliser iframe',
      width: 'Largeur',
      height: 'Hauteur',
      buttonColor: 'Couleur bouton',
      buttonColorHover: 'Couleur bouton hover',
      buttonColorActive: 'Couleur bouton actif',
      backgroundColorInput: 'Couleur fond input',
      backgroundColor: 'Couleur fond',
      backgroundColorUser: 'Couleur fond bulle utilisateur',
      backgroundColorAssistant: 'Couleur fond bulle assistant',
      backgroundColorHeader: 'Couleur fond en-tête',
      textColor: 'Couleur texte',
      textColorHeader: 'Couleur texte en-tête',
      textColorButton: 'Couleur texte bouton',
      textColorInput: 'Couleur texte input',
      borderColor: 'Couleur bord',
      required_feedback: 'Veuillez écrire votre feedback',
      help: `<ul>
<li>Le bouton "Copier" permet de copier le message de l'assistant dans le presse papier</li>
<li>Le bouton "Feedback" permet d'envoyer un feedback au créateur de l'assistant, au cas où une réponse ne serait pas pertinente par exemple. Ceci permettra d'améliorer l'assistant.</li>
</ul>`,
      iframeImageChanged: "L'image du bouton d'intégration a été changée",
      submitIframeImage: 'Changer image',
      iframeImage: "Image du bouton d'intégration",
      sendMailForm_sent:
        "Un mail avec la conversation a été envoyé au créateur de l'assistant",
      empty_response_send_formTip:
        "Si l'assistant ne trouve pas de réponse pertinente dans la base de connaissance, il peut proposer à l'utilisateur d'envoyer la conversation par mail pour qu'un humain puisse répondre.",
      empty_response_send_form: 'Assistance mail',
      sendMailForm:
        "La base de connaissance ne contient rien de pertinent. Souhaitez-vous envoyer cette conversation au créateur de l'assistant pour qu'il puisse vous répondre par mail ?",
      chatBotLimit: "Nombre d'assistants: {{chatbots_count}}/{{max_chatbots}}",
      newConversation: 'Nouvelle conversation',
      createAssistant: 'Créer un Assistant',
      assistantSetting: "Configuration de l'Assistant",
      promptEngine: "Moteur d'invite",
      modelSetting: 'Réglage du modèle',
      chat: 'Chat',
      newChat: 'Nouveau chat',
      send: 'Envoyer',
      sendPlaceholder: 'Message',
      chatConfiguration: 'Configuration du Chat',
      chatConfigurationDescription:
        'Ici, habillez un assistant dédié pour vos bases de connaissances spéciales !',
      assistantName: "Nom de l'assistant",
      assistantNameMessage: "Le nom de l'assistant est requis",
      namePlaceholder: 'ex. : Resume Jarvis',
      assistantAvatar: "Avatar de l'assistant",
      language: 'Langue',
      emptyResponse: 'Réponse vide',
      emptyResponseTip: `Si aucune information n'est récupérée avec la question de l'utilisateur dans la base de connaissances, cela sera utilisé comme réponse. Si vous souhaitez que le LLM propose son propre avis lorsque rien n'est récupéré, laissez ce champ vide.`,
      setAnOpener: 'Définir une ouverture',
      setAnOpenerInitial: `Salut ! Je suis votre assistant, que puis-je faire pour vous ?`,
      setAnOpenerTip: 'Comment souhaitez-vous accueillir vos clients ?',
      knowledgeBases: 'Bases de connaissances',
      knowledgeBasesMessage: 'Veuillez sélectionner',
      knowledgeBasesTip: 'Sélectionnez les bases de connaissances associées.',
      system: 'Système',
      systemInitialValue: `Vous êtes un assistant intelligent. Veuillez résumer le contenu de la base de connaissances pour répondre à la question. Veuillez lister les données dans la base de connaissances et répondre en détail. Si la base de connaissances est vide, votre réponse doit inclure la phrase "La réponse que vous cherchez n'est pas trouvée dans la base de connaissances !" Les réponses doivent prendre en compte l'historique de la conversation.
Voici la base de connaissances :
{knowledge}
Ceci est la base de connaissances.`,
      systemMessage: 'Veuillez entrer !',
      systemTip:
        'Instructions que vous avez besoin que le LLM suive lorsque LLM répond aux questions, comme la conception du personnage, la longueur de la réponse et la langue de la réponse, etc.',
      topN: 'Top N',
      topNTip: `Tous les segments dont le score de similarité dépasse le 'seuil de similarité' ne seront pas tous transmis aux LLMs. Le LLM ne peut voir que ces segments 'Top N'.`,
      variable: 'Variable',
      variableTip: `Si vous utilisez des API de dialogue, les variables pourraient vous aider à dialoguer avec vos clients selon différentes stratégies. 
      Les variables sont utilisées pour remplir la partie 'Système' dans l'invite afin de donner un indice au LLM.
      'knowledge' est une variable très spéciale qui sera remplie avec les segments récupérés.
      Toutes les variables dans 'Système' doivent être encadrées par des accolades.`,
      add: 'Ajouter',
      key: 'Clé',
      optional: 'Optionnel',
      operation: 'Opération',
      model: 'Modèle',
      modelTip: 'Modèle de conversation en langage naturel',
      modelMessage: 'Veuillez sélectionner !',
      freedom: 'Liberté',
      improvise: 'Improviser',
      precise: 'Précis',
      balance: 'Équilibre',
      freedomTip: `'Précis' signifie que le LLM sera conservateur et répondra à votre question prudemment. 'Improviser' signifie que vous voulez que le LLM parle beaucoup et librement. 'Équilibre' est entre prudemment et librement.`,
      temperature: 'Température',
      temperatureMessage: 'La température est requise',
      temperatureTip:
        "Ce paramètre contrôle le niveau de hasard des prédictions du modèle. Une température plus basse rend le modèle plus confiant dans ses réponses, tandis qu'une température plus élevée le rend plus créatif et diversifié.",
      topP: 'Top P',
      topPMessage: 'Top P est requis',
      topPTip:
        'Également connu sous le nom de "échantillonnage du noyau", ce paramètre établit un seuil pour sélectionner un ensemble plus petit de mots à échantillonner. Il se concentre sur les mots les plus probables, en éliminant les moins probables.',
      presencePenalty: 'Pénalité de Présence',
      presencePenaltyMessage: 'La Pénalité de Présence est requise',
      presencePenaltyTip:
        'Cela décourage le modèle de répéter la même information en pénalisant les mots qui sont déjà apparus dans la conversation.',
      frequencyPenalty: 'Pénalité de Fréquence',
      frequencyPenaltyMessage: 'La Pénalité de Fréquence est requise',
      frequencyPenaltyTip:
        'Similaire à la pénalité de présence, cela réduit la tendance du modèle à répéter fréquemment les mêmes mots.',
      maxTokens: 'Jetons Maximum',
      maxTokensMessage: 'Les Jetons Maximum sont requis',
      maxTokensTip:
        'Cela définit la longueur maximale de la sortie du modèle, mesurée en nombre de jetons (mots ou morceaux de mots).',
      quote: 'Afficher Citation',
      quoteTip: 'La source du texte original doit-elle être affichée ?',
      selfRag: 'Self-RAG',
      selfRagTip:
        'Veuillez consulter : https://huggingface.co/papers/2310.11511',
      overview: 'API de Chat Bot',
      pv: 'Nombre de messages',
      uv: "Nombre d'utilisateurs actifs",
      speed: 'Vitesse de sortie des jetons',
      tokens: 'Consommer le nombre de jetons',
      round: "Nombre d'interactions de session",
      thumbUp: 'Satisfaction client',
      preview: 'Aperçu',
      embedded: 'Intégré',
      serviceApiEndpoint: "Point de terminaison de l'API de service",
      apiKey: 'Clé API',
      apiReference: 'Documents API',
      dateRange: 'Plage de dates :',
      backendServiceApi: 'API de service backend',
      createNewKey: 'Créer une nouvelle clé',
      created: 'Créé',
      action: 'Action',
      embedModalTitle: 'Intégrer dans un site web',
      comingSoon: 'Bientôt disponible',
      fullScreenTitle: 'Intégration complète',
      fullScreenDescription:
        "Intégrez l'iframe suivant dans votre site web à l'emplacement souhaité",
      partialTitle: 'Intégration partielle',
      extensionTitle: 'Extension Chrome',
      tokenError: "Veuillez d'abord créer un jeton API !",
      searching: 'recherche en cours...',
      maxChatbotLimitReached:
        "La limite d'assistants ({{chatbots_count}}/{{max_chatbots}}) a été atteinte ",
    },
    setting: {
      modelProvidersWarn: `Veuillez demander à un administrateur d'ajouter un modèle d'embedding et un LLM pour votre compte.`,
      create_date: 'Date de création',
      needDisable: 'Doit être désactivé avant effacer',
      needEnable: 'Doit être activé avant modifier',
      active: 'Actif',
      chatbot_iframe: "Intégration d'assistant",
      botzilla_register: 'Bienvenue chez Botzilla!',
      initialPassword: 'Mot de passe envoyé par e-mail',
      botzilla_register_description:
        "Veuillez choisir un nom d'utilisateur et un mot de passe avant de pouvoir profiter de Botzilla!",
      completeFormPlease:
        'Veuillez remplir les champs obligatoires du formulaire !',
      max_users: "Nombre d'utilisateur max",
      max_chatbots: "Nombre d'assistants max",
      company_user_create_success:
        'a reçu un mail avec les informations de connexion',
      company_createuser: "Gestion d'utilisateurs",
      company_tokens: 'Tokens utilisés',
      admin_tokens: 'Tokens utilisés par entreprises',
      company_createuser_title: "Création d'utilisateur pour l'entreprise",
      company_manageuser_title: "Gestion d'utilisateurs de l'entreprise",
      company_createuser_description:
        "Entrez l'e-mail avec lequel l'utilisateur devra se connecter.<br/>Un mot de passe temporaire sera envoyé à cet e-mail.<br/>Ce mot de passe devra être changé à la première connexion.",
      company_manageuser_description:
        "Nombre d'utilisateurs: {{user_count}}/{{max_users}}",
      create_company: "Gestion d'entreprises",
      update_max_limits: 'Mettre à jour',
      admin_createuser_title: "Création d'entreprise",
      admin_createuser_description:
        "Entrez l'e-mail avec lequel l'entreprise devra se connecter ainsi que le nombre d'utilisateurs et d'assistant max auquel l'entreprise a droit.<br/>Un mot de passe temporaire sera envoyé à cet e-mail.<br/>Ce mot de passe devra être changé à la première connexion.",
      submit_create_company: 'Créer entreprise',
      required_new_email_message: 'Veuillez entrer un e-mail',
      admin_manageuser_title: "Gestion d'entreprises",
      admin_manageuser_description: '',
      chatbot: 'Assistants',
      chatbotLabel: 'Assistants permis',
      addChatbotTitle: 'Ajouter assistants',
      submit_create_user: 'Créer utilisateur',
      delete: 'Supprimer',
      company_tokens_title: 'Tokens utilisés',
      company_tokens_description:
        'Ici vous trouverez le nombre de tokens utilisés par modèle.',
      llm_name: 'Nom du LLM',
      used_tokens: 'Tokens utilisés',
      admin_tokens_title: 'Tokens utilisés par entreprise',
      admin_tokens_description:
        'Ici vous trouverez le nombre de tokens utilisés par modèle par entreprise',
      boss_name: "Nom de l'entreprise",
      sortAsc: "Cliquer pour trier dans l'ordre ascendant",
      sortDesc: "Cliquer pour trier dans l'ordre descendant",
      cancelSort: 'Cliquer pour annuler tri',
      profile: 'Profil',
      profileDescription:
        'Mettez à jour votre photo et vos informations personnelles ici.',
      password: 'Mot de passe',
      passwordDescription:
        'Veuillez entrer votre mot de passe actuel pour changer de mot de passe.',
      model: 'Fournisseurs de modèle',
      modelDescription: 'Configurez ici le paramètre du modèle et la clé API.',
      team: 'Équipe',
      system: 'Système',
      logout: 'Déconnexion',
      username: "Nom d'utilisateur",
      usernameMessage: "Veuillez entrer votre nom d'utilisateur !",
      photo: 'Votre photo',
      photoDescription: 'Ceci sera affiché sur votre profil.',
      colorSchema: 'Schéma de couleurs',
      colorSchemaMessage: 'Veuillez sélectionner votre schéma de couleurs !',
      colorSchemaPlaceholder: 'sélectionnez votre schéma de couleurs',
      bright: 'Lumineux',
      dark: 'Sombre',
      timezone: 'Fuseau horaire',
      timezoneMessage: 'Veuillez entrer votre fuseau horaire !',
      timezonePlaceholder: 'sélectionnez votre fuseau horaire',
      email: 'Adresse e-mail',
      emailDescription:
        "Une fois enregistré, l'E-mail ne peut pas être changé.",
      currentPassword: 'Mot de passe actuel',
      currentPasswordMessage: 'Veuillez entrer votre mot de passe !',
      newPassword: 'Nouveau mot de passe',
      newPasswordMessage: 'Veuillez entrer votre mot de passe !',
      newPasswordDescription:
        'Votre nouveau mot de passe doit contenir plus de 8 caractères.',
      confirmPassword: 'Confirmez le nouveau mot de passe',
      confirmPasswordMessage: 'Veuillez confirmer votre mot de passe !',
      confirmPasswordNonMatchMessage:
        'Le nouveau mot de passe que vous avez entré ne correspond pas !',
      cancel: 'Annuler',
      addedModels: 'Modèles ajoutés',
      modelsToBeAdded: 'Modèles à ajouter',
      addTheModel: 'Ajouter un modèle',
      apiKey: 'Clé API',
      apiKeyMessage: 'Veuillez entrer la clé API !',
      apiKeyTip:
        'La clé API peut être obtenue en enregistrant le fournisseur LLM correspondant.',
      showMoreModels: 'Afficher modèles en détail',
      baseUrl: 'URL de base',
      baseUrlTip:
        'Si votre clé API provient de OpenAI, ignorez cela. Tout autre fournisseur intermédiaire donnera cette URL de base avec la clé API.',
      modify: 'Modifier',
      systemModelSettings: 'Paramètres du modèle système',
      chatModel: 'Modèle de chat',
      chatModelTip:
        'Le modèle LLM de chat par défaut que toutes les bases de connaissances nouvellement créées utiliseront.',
      embeddingModel: "Modèle d'encodage",
      embeddingModelTip:
        "Le modèle d'encodage par défaut que toutes les bases de connaissances nouvellement créées utiliseront.",
      img2txtModel: 'Modèle Img2txt',
      img2txtModelTip:
        'Le modèle multi-module par défaut que toutes les bases de connaissances nouvellement créées utiliseront. Il peut décrire une image ou une vidéo.',
      sequence2txtModel: 'Modèle Sequence2txt',
      sequence2txtModelTip:
        'Le modèle ASR par défaut que toutes les bases de connaissances nouvellement créées utiliseront. Utilisez ce modèle pour traduire les voix en texte correspondant.',
      rerankModel: 'Modèle de réévaluation',
      rerankModelTip:
        'Le modèle de réévaluation par défaut est utilisé pour réévaluer les segments récupérés par les questions des utilisateurs.',
      workspace: 'Espace de travail',
      upgrade: 'Améliorer',
      addLlmTitle: 'Ajouter LLM',
      modelName: 'Nom du modèle',
      modelUid: 'UID du modèle',
      modelNameMessage: 'Veuillez entrer le nom de votre modèle !',
      modelType: 'Type de modèle',
      modelTypeMessage: 'Veuillez entrer le type de votre modèle !',
      addLlmBaseUrl: 'URL de base',
      baseUrlNameMessage: 'Veuillez entrer votre URL de base !',
      vision: 'Prend-il en charge la vision ?',
      ollamaLink: 'Comment intégrer {{name}}',
      volcModelNameMessage:
        'Veuillez entrer le nom de votre modèle ! Format: {"ModelName":"EndpointID"}',
      addVolcEngineAK: 'CLÉ D’ACCÈS VOLC',
      volcAKMessage: 'Veuillez entrer votre CLÉ D’ACCÈS VOLC',
      addVolcEngineSK: 'CLÉ SECRÈTE VOLC',
      volcSKMessage: 'Veuillez entrer votre CLÉ SECRÈTE',
      bedrockModelNameMessage: 'Veuillez entrer le nom de votre modèle !',
      addBedrockEngineAK: 'CLÉ D’ACCÈS',
      bedrockAKMessage: 'Veuillez entrer votre CLÉ D’ACCÈS',
      addBedrockSK: 'CLÉ SECRÈTE',
      bedrockSKMessage: 'Veuillez entrer votre CLÉ SECRÈTE',
      bedrockRegion: 'Région AWS',
      bedrockRegionMessage: 'Veuillez sélectionner !',
      'us-east-1': 'Est des États-Unis (Virginie du Nord)',
      'us-west-2': 'Ouest des États-Unis (Oregon)',
      'ap-southeast-1': 'Asie-Pacifique (Singapour)',
      'ap-northeast-1': 'Asie-Pacifique (Tokyo)',
      'eu-central-1': 'Europe (Francfort)',
      'us-gov-west-1': 'AWS GovCloud (Ouest des États-Unis)',
      'ap-southeast-2': 'Asie-Pacifique (Sydney)',
      botzilla_admin: 'Gérer rôles utilisateurs',
      botzilla_model: 'Gérer modèles entreprises',
      select_user_promote_company:
        'Sélectionnez un compte utilisateur pour le promouvoir en compte entreprise',
      submit_company_promotion: 'Promouvoir',
      company_promotion_success: 'a été promu en compte entreprise',
      submit_show_models: 'Montrer modèles du compte',
      select_mail_label: 'E-mail du compte',
      required_email_message: 'Veuillez sélectionner un e-mail',
      select_mail: 'E-mail',
      user_promote_company_title: 'Promotion de compte',
      maxUsersLimitReached:
        "La limite d'utilisateurs ({{user_count}}/{{max_users}}) a été atteinte",
    },
    message: {
      feedback_sent: 'Votre feedback a été envoyé',
      registered: 'Enregistré !',
      logout: 'déconnexion',
      logged: 'connecté !',
      pleaseSelectChunk: 'Veuillez sélectionner un segment !',
      modified: 'Modifié',
      created: 'Créé',
      deleted: 'Supprimé',
      renamed: 'Renommé',
      operated: 'Opéré',
      updated: 'Mis à jour',
      uploaded: 'Téléchargé',
      200: 'Le serveur a retourné avec succès les données demandées.',
      201: 'Création ou modification des données réussie.',
      202: "Une requête a été mise en file d'attente en arrière-plan (tâche asynchrone).",
      204: 'Données supprimées avec succès.',
      400: "Il y a eu une erreur dans la demande émise, et le serveur n'a pas créé ou modifié les données.",
      401: "L'utilisateur n'a pas les permissions nécessaires (jeton, nom d'utilisateur ou mot de passe incorrect).",
      403: "L'utilisateur est autorisé, mais l'accès est interdit.",
      404: "La requête a été faite pour un enregistrement qui n'existe pas, et le serveur n'a pas effectué l'opération.",
      406: "Le format demandé n'est pas disponible.",
      410: 'La ressource demandée a été définitivement supprimée et ne sera plus disponible.',
      422: "Lors de la création d'un objet, une erreur de validation s'est produite.",
      500: "Une erreur de serveur s'est produite, veuillez vérifier le serveur.",
      502: 'Erreur de passerelle.',
      503: 'Le service est indisponible et le serveur est temporairement surchargé ou en maintenance.',
      504: "Délai d'attente de la passerelle.",
      requestError: 'Erreur de requête',
      networkAnomalyDescription:
        'Il y a une anomalie dans votre réseau et vous ne pouvez pas vous connecter au serveur.',
      networkAnomaly: 'anomalie réseau',
      hint: 'astuce',
    },
    fileManager: {
      name: 'Nom',
      uploadDate: 'Date de téléchargement',
      knowledgeBase: 'Base de connaissances',
      size: 'Taille',
      action: 'Action',
      addToKnowledge: 'Lier à la base de connaissances',
      pleaseSelect: 'Veuillez sélectionner',
      newFolder: 'Nouveau dossier',
      file: 'Fichier',
      uploadFile: 'Télécharger fichier',
      directory: 'Dossier',
      uploadTitle:
        'Cliquez ou glissez le fichier dans cette zone pour télécharger',
      uploadDescription: 'Support pour un téléchargement unique ou en masse.',
      local: 'Téléchargements locaux',
      s3: 'Téléchargements S3',
      preview: 'Aperçu',
      fileError: 'Erreur de fichier',
    },
    flow: {
      cite: 'Citer',
      citeTip: 'Conseil de citation',
      name: 'Nom',
      nameMessage: 'Veuillez entrer un nom',
      description: 'Description',
      examples: 'Exemples',
      to: 'À',
      msg: 'Messages',
      messagePlaceholder: 'message',
      messageMsg: 'Veuillez entrer un message ou supprimer ce champ.',
      addField: 'Ajouter un champ',
      loop: 'Boucle',
      yes: 'Oui',
      no: 'Non',
      key: 'Clé',
      componentId: 'identifiant du composant',
      add: 'Ajouter',
      operation: 'Opération',
      run: 'Exécuter',
      save: 'Enregistrer',
      title: 'Titre:',
      beginDescription: "C'est là que commence le flux",
      answerDescription:
        "Ce composant est utilisé comme interface entre le bot et l'humain. Il reçoit l'entrée de l'utilisateur et affiche le résultat du calcul du bot.",
      retrievalDescription:
        'Ce composant est pour le processus de récupération des informations pertinentes de la base de connaissances. Donc, les bases de connaissances doivent être sélectionnées. S\'il n\'y a rien récupéré, une "Réponse vide" sera retournée.',
      generateDescription:
        "Ce composant est utilisé pour appeler le LLM afin de générer du texte. Faites attention au réglage de l'invite.",
      categorizeDescription:
        'Ce composant est utilisé pour catégoriser le texte. Veuillez spécifier le nom, la description et les exemples de la catégorie. Chaque catégorie entraîne différents composants en aval.',
      relevantDescription:
        'Ce composant est utilisé pour juger si la sortie en amont est pertinente par rapport à la dernière question de l\'utilisateur. "Oui" représente qu\'ils sont pertinents. "Non" représente qu\'ils sont non pertinents.',
      rewriteQuestionDescription:
        'Ce composant est utilisé pour affiner la question de l\'utilisateur. Typiquement, lorsque la question originale de l\'utilisateur ne peut pas récupérer des informations pertinentes de la base de connaissances, ce composant vous aide à changer la question en une plus adaptée qui pourrait être plus cohérente avec les expressions dans la base de connaissances. Seul "Récupération" peut être son aval.',
      messageDescription:
        "Ce composant est utilisé pour envoyer des informations statiques à l'utilisateur. Vous pouvez préparer plusieurs messages qui seront choisis aléatoirement.",
      keywordDescription:
        "Ce composant est utilisé pour extraire des mots-clés de la question de l'utilisateur. Top N spécifie le nombre de mots-clés que vous devez extraire.",
      wikipediaDescription:
        'Ce composant est utilisé pour obtenir le résultat de recherche de https://www.wikipedia.org/. Typiquement, il fonctionne comme un complément aux bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      promptText:
        'Veuillez résumer les paragraphes suivants. Faites attention aux nombres, ne fabriquez pas de faits. Voici les paragraphes : {input}',
      createGraph: 'Créer un agent',
      createFromTemplates: 'Créer à partir de modèles',
      retrieval: 'Récupération',
      generate: 'Générer',
      answer: 'Réponse',
      categorize: 'Catégoriser',
      relevant: 'Pertinent',
      rewriteQuestion: 'Réécrire',
      rewrite: 'Réécrire',
      begin: 'Commencer',
      message: 'Message',
      blank: 'Vide',
      createFromNothing: 'Créer de rien',
      addItem: 'Ajouter un élément',
      nameRequiredMsg: 'Le nom est requis',
      nameRepeatedMsg: 'Le nom ne peut pas être répété',
      keywordExtract: 'Extraction de mots-clés',
      keywordExtractDescription:
        "Ce composant est utilisé pour extraire des mots-clés de la question de l'utilisateur. Top N spécifie le nombre de mots-clés que vous devez extraire.",
      baidu: 'Baidu',
      baiduDescription:
        'Ce composant est utilisé pour obtenir des résultats de recherche de www.baidu.com. Typiquement, il sert de complément aux bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Ce composant est utilisé pour obtenir des résultats de recherche de www.duckduckgo.com. Typiquement, il sert de complément aux bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      channel: 'Canal',
      channelTip:
        "Effectuez une recherche de texte ou de nouvelles sur l'entrée du composant",
      text: 'Texte',
      news: 'Nouvelles',
      messageHistoryWindowSize:
        "Taille de la fenêtre de l'historique des messages",
      messageHistoryWindowSizeTip:
        "La taille de la fenêtre de l'historique de conversation que le LLM doit voir. Plus elle est grande, mieux c'est. Mais faites attention à la longueur maximale de contenu du LLM.",
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Email',
      emailTip:
        "Ce composant est utilisé pour obtenir des résultats de recherche de https://pubmed.ncbi.nlm.nih.gov/. Typiquement, il sert de complément aux bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter. L'email est un champ obligatoire.",
      arXiv: 'ArXiv',
      arXivTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche de https://arxiv.org/. Typiquement, il sert de complément aux bases de connaissances. Top N spécifie le nombre de résultats de recherche que vous devez adapter.',
      sortBy: 'Trier par',
      submittedDate: 'Date de soumission',
      lastUpdatedDate: 'Date de dernière mise à jour',
      relevance: 'Pertinence',
      google: 'Google',
      googleTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche dehttps://www.google.com/. Typiquement, il sert de complément aux bases de connaissances. Top N et SerpApi API key spécifient le nombre de résultats de recherche que vous devez adapter.',
      bing: 'Bing',
      bingTip:
        'Ce composant est utilisé pour obtenir des résultats de recherche de https://www.bing.com/. Typiquement, il sert de complément aux bases de connaissances. Top N et Bing Subscription-Key spécifient le nombre de résultats de recherche que vous devez adapter.',
      apiKey: 'Clé API',
      country: 'Pays',
      language: 'Langue',
    },
    footer: {
      profile: 'Tous droits réservés @ React',
    },
    layout: {
      file: 'fichier',
      knowledge: 'connaissance',
      chat: 'chat',
    },
    statusPages: {
      title_404: 'Erreur 404',
      subtitle_404:
        "Désolé, mais la page que vous voulez visiter n'existe pas.",
      backhome: "Retour à l'accueil",
      title_403: 'Erreur 403',
      subtitle_403:
        "Désolé, mais vous n'avez pas le droit de visiter cette page.",
    },
    home: {
      welcome: 'Bienvenue, {{nickname}}',
      description: '',
      changePassword_title: 'Vous devez changer votre mot de passe',
      changePassword_description:
        "Votre mot de passe actuel est le même que celui généré lors de la création du compte.<br/>Pour des raisons de sécurité, vous devez changer votre mot de passe.<br/>Veuillez vous rendre dans <0>les paramètres</0> afin d'y changer votre mot de passe.<br/>Profitez-en aussi pour changer de nom d'utilisateur.",
    },
  },
};
